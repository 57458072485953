/*global Component, wrapPost, wrapGet */
class recoveryPasswordPage extends Component {

    static name() {
        return "recoveryPasswordPage";
    }

    static componentName() {
        return "recoveryPasswordPage";
    }

    data() {
        return {
            filter: '',
            personCode: '',
            readyToSend: false,
            disabledBtn: false,
            sending: false

        };
    }

    getWatch() {
        return {
            'filter': function () {
                if (!this.filter.length)
                    this.readyToSend = false;
            },
        };
    }

    getMethods() {
        return {
            loadPerson: async function () {
                if (this.filter.length) {
                    this.disabledBtn = true;
                    await wrapGet(`/${this.$store.getters.getSetting.PathURL}/api/loadPerson/` + btoa(this.filter))
                        .then(response => {
                            if (response.data.ok) {
                                this.personCode = response.data.personCode;
                                this.Email = response.data.email;
                                this.readyToSend = true;
                            } else {
                                this.filter = '';
                                this.personCode = '';
                                //this.Email = '';
                                this.readyToSend = false;
                                this.$store.dispatch('showNotificacion', {
                                    title: '',
                                    message: response.data.error,
                                    type: 'error'
                                });
                            }
                            this.disabledBtn = false;
                        })
                        .catch((error) => {
                            this.disabledBtn = false;
                        });

                    if (this.readyToSend)
                        await this.sendMailRecoveryPassword();

                }
            },
            sendMailRecoveryPassword: async function () {
                this.sending = true;
                let data = {
                    personCode: this.personCode,
                    settingCode: this.$store.getters.getSetting.Code
                    //Email: this.Email
                };
                return wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/sendMailRecoveryPassword`, data)
                    .then(response => {
                        if (response.data.ok) {
                            this.readyToSend = false;
                            this.$store.dispatch('showNotificacion', {
                                title: 'Check your Mail',
                                message: 'Message sent',
                                type: 'success'
                            });
                            this.$router.push("/login");
                        } else {
                            this.readyToSend = false;
                            this.$store.dispatch('showNotificacion', {
                                title: 'Ecommerce Settings Error',
                                message: response.data.error,
                                type: 'error'
                            });
                        }
                        this.sending = false;

                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log('Error response in sendMailRecoveryPassword', error);
                    });
            }
        };
    }

    getTemplate() {
        return `<div>
                 <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer">
                      <div class="section-main">
                        <section class="padding-small">
                          <template v-if="$store.getters.getAppState=='ready'">
                            <div class="container">
                                <div class="row justify-content-center">
                                  <div class="col-lg-8">
                                   <template v-if="!sending">
                                        <div class="block">
                                          <div class="block-header">
                                            <h5 class="text-center">{{tr('Recover Password')}}</h5>
                                          </div>
                                          <div class="block-body">
                                            <p class="lead text-center">{{tr('Please write your username or email')}}</p>
                                            <hr>
                                                <form aria-autocomplete="none" autocomplete="off">
                                                    <!--<p class="lead text-center green" v-if="readyToSend">{{tr('Data was checked. You will receive an email to complete the password recovery process')}}</p>-->
                                                        <div class="form-group">
                                                            <input  :placeholder="tr('User or email')" v-model="filter" name="filter" type="text" class="form-control validate" aria-autocomplete="none" autocomplete="none">
                                                        </div>
                                                      <div class="form-group text-right">
                                                          <router-link  to="/home" class="btn btn-light" role="button">{{tr('Close')}}</router-link>
                                                          <!--<a v-if="readyToSend && !sending" class="btn btn-secondary active" role="button" @click.prevent="sendMailRecoveryPassword">{{tr('Send Mail')}}</a>
                                                          <a v-if="readyToSend && sending" class="btn btn-secondary disabled" role="button">{{tr('Send Mail')}}</a>-->
                                                          <button  class="center-align btn btn-secondary" @click.prevent="loadPerson" :disabled="disabledBtn">{{tr('Send Mail')}}</button>
                                                      </div>
                                                    <hr>
                                                </form>
                                          </div>
                                          </div>
                                      </template>
                                      <template v-if="sending">
                                              <div class="loading">
                                                  <i class="fas fa-spinner fa-spin fa-3x"></i>
                                              </div>
                                        </template>
                                    </div>
                                  </div>
                                </div>
                          </template>
                          <template v-if="$store.getters.getAppState=='onload'">
                              <div class="loading">
                                  <i class="fas fa-spinner fa-spin fa-3x"></i>
                              </div>
                          </template>
                        </section>
                      </div>
                      </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

recoveryPasswordPage.registerComponent();
